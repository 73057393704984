<template>
  <div>
    <v-container>
      <v-row style="margin-top: 50px" v-if="carregando" dense>
        <v-col cols="12" class="text-center">
          <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
        </v-col>
      </v-row>

      <v-form v-else v-model="valid" ref="form" lazy-validation>
        <v-widget title="Novo Pedido de Cliente" :temCampoObrigatorio="true">
          <div slot="widget-content">
            <v-row dense>
              <v-col cols="12" sm="1">
                <v-text-field v-model="pedido_cliente.numero" name="numero" label="Número" autocomplete="off" readonly></v-text-field>
              </v-col>
              <v-col cols="12" sm="2">
                <campo-data label="Data" v-model="pedido_cliente.data" :rules="[rules.obrigatorio]" ref="data" @keypress.native.enter="$refs.cliente.focus()"></campo-data>
              </v-col>
              <v-col cols="4" sm="3">
                <buscar-participante label="Buscar Cliente" ref="cliente" v-model="pedido_cliente.participante.cnpj_cpf" @participanteSelecionado="adicionarParticipante" :rules="[rules.obrigatorio]" autofocus></buscar-participante>
              </v-col>
              <v-col cols="8" sm="6">
                <v-text-field label="Cliente" v-model="pedido_cliente.participante.razao_nome" readonly ref="razao_nome" @keypress.native.enter="$refs.documento.focus()"></v-text-field>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="6" sm="2">
                <as-campo-valor label="Quantidade" v-model="pedido_cliente.quantidade" :decimais="2" readonly></as-campo-valor>
              </v-col>
              <v-col cols="6" sm="2">
                <as-campo-valor label="Valor" v-model="pedido_cliente.valor" :decimais="2" readonly></as-campo-valor>
              </v-col>
            </v-row>

            <v-divider class="mt-5 mb-5"></v-divider>

            <v-card>
              <v-toolbar flat dark color="primary" dense>
                <v-toolbar-title>Produtos</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn color="primary" class="lighten-2 ml-2" depressed  @click="abrirBuscaProdutos">Adicionar Produto</v-btn>
              </v-toolbar>
              <v-card-text>
                <v-data-table :headers="headers" :items="pedido_cliente.produtos" hide-default-footer disable-pagination fixed-header height="350px">
                  <template v-slot:item="props">
                    <tr>
                      <td class="text-center">{{ props.item.codigo }}</td>
                      <td>{{ props.item.descricao }}</td>
                      <td class="text-center">{{ props.item.unidade }}</td>
                      <td class="text-center">{{ props.item.quantidade | formatarValor }}</td>
                      <td class="text-center">{{ props.item.valor | formatarMoeda }}</td>
                      <td class="text-center">{{ props.item.desconto | formatarValor }} %</td>
                      <td class="text-center">{{ props.item.subtotal | formatarMoeda }}</td>
                      <td class="text-center">
                        <v-menu top right transition="slide-x-transition">
                          <template v-slot:activator="{ on }">
                            <v-icon @click.stop="" v-on="on">more_vert</v-icon>
                          </template>
                          <v-list dense>
                            <v-list-item :key="props.item.produto + 10" @click="editarProduto(props.item)">
                              <v-list-item-icon><v-icon>edit</v-icon></v-list-item-icon>
                              <v-list-item-title>Editar</v-list-item-title>
                            </v-list-item>
                            <v-list-item :key="props.item.produto +15" @click="removerProduto(props.item)">
                              <v-list-item-icon><v-icon>delete</v-icon></v-list-item-icon>
                              <v-list-item-title>Remover</v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </div>

          <template v-slot:widget-footer-action>
            <v-btn text @click="cancelar" :disabled="salvando">Cancelar</v-btn>
            <v-btn color="primary" :loading="salvando" @click="salvar" :disabled="!valid">Salvar</v-btn>
          </template>
        </v-widget>
      </v-form>
    </v-container>

    <v-dialog v-model="dialogQuantidade" max-width="800px" persistent scrollable>
      <v-card>
        <v-toolbar flat dark color="primary" dense>
          <v-toolbar-title>Quantidade do Produto</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-card-text class="pt-1">
          <v-row dense>
            <v-col cols="12" sm="3">
              <v-text-field v-model="produto.codigo" name="codigo" label="Código" autocomplete="off" readonly></v-text-field>
            </v-col>
            <v-col cols="12" sm="9">
              <v-text-field v-model="produto.descricao" name="descricao" label="Descrição" autocomplete="off" readonly></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="3">
              <v-select label="Unidade" v-model="produto.unidade" :items="constantes.UNIDADES" :rules="[rules.obrigatorio]" ref="unidade" @keypress.native.enter="$refs.quantidade.focus()"></v-select>
            </v-col>
            <v-col cols="12" sm="2">
              <as-campo-valor label="Quantidade" v-model="produto.quantidade" :rules="[rules.obrigatorio, rules.valorMaiorQueZero]" :decimais="2" ref="quantidade" @blur="calcularSubtotal" @keypress.native.enter="$refs.valor.focus()"></as-campo-valor>
            </v-col>
            <v-col cols="12" sm="2">
              <as-campo-valor label="Valor" v-model="produto.valor" :rules="[rules.obrigatorio, rules.valorMaiorQueZero]" :decimais="2" ref="valor" @blur="calcularSubtotal" @keypress.native.enter="$refs.desconto.focus()"></as-campo-valor>
            </v-col>
            <v-col cols="12" sm="2">
              <as-campo-valor label="Desconto %" v-model="produto.desconto" :rules="[rules.obrigatorio, rules.valorPositivo]" :decimais="2" ref="desconto" @blur="calcularSubtotal" @keypress.native.enter="salvarProduto"></as-campo-valor>
            </v-col>
            <v-col cols="12" sm="3">
              <as-campo-valor label="SubTotal" v-model="produto.subtotal" :decimais="2" ref="subtotal" readonly></as-campo-valor>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="dialogQuantidade=false" text>Cancelar</v-btn>
          <v-btn color="primary" @click="salvarProduto">Salvar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <dialog-buscar-produto ref="dialogBuscarProduto" @produtoSelecionado="selecionarProduto"></dialog-buscar-produto>
  </div>
</template>

<script>

import {mapState, mapGetters, mapActions, mapMutations} from "vuex";
import { mask } from 'vue-the-mask';
import constantes from '@/util/constantes';
import * as regras from '@/util/validacaoFormulario';
import formatador from '@/util/formatador';
import DialogBuscarProduto from '@/components/dialogs/DialogBuscarProduto';
import { deepClone } from "@/util/helpers";

export default {
  name: 'FormularioPedidosClientes',
  components:{
    DialogBuscarProduto
  },
  data () {
    return {
      valid: true,
      salvando: false,
      rules: {...regras},
      headers: [
        { text: 'Código', value: 'codigo', sortable: false, width: '10%', align: 'center' },
        { text: 'Descrição', value: 'descricao', sortable: false, width: '40%' },
        { text: 'Unidade', value: 'unidade', sortable: false, width: '5%' , align: 'center'},
        { text: 'Quantidade', value: 'quantidade', sortable: false, width: '10%', align: 'center' },
        { text: 'Valor', value: 'valor', sortable: false, width: '10%', align: 'center' },
        { text: 'Desconto', value: 'desconto', sortable: false, width: '10%', align: 'center' },
        { text: 'Subtotal', value: 'subtotal', sortable: false, width: '10%', align: 'center' },
        { text: 'Ações', value: 'acoes', sortable: false, width: '5%', align: 'center' },
      ],
      produto: {},
      dialogQuantidade: false,
      constantes: constantes,
      modoEdicao: false,
    }
  },
  computed: {
    ...mapState('pedidosClientes', {
      pedido_cliente: 'item',
      carregando: 'carregando',
    }),
    totais () {
      let quantidade_total = this.pedido_cliente.produtos.reduce((soma, produto) => {
        return soma + produto.quantidade;
      }, 0);

      let valor_total = this.pedido_cliente.produtos.reduce((soma, produto) => {
        return soma + produto.subtotal
      }, 0);

      return {
        quantidade: quantidade_total,
        valor: valor_total
      }
    }
  },
  methods: {
    async salvar () {
      if (this.pedido_cliente.participante._id) {
        if (this.pedido_cliente.produtos.length > 0) {
          if (this.pedido_cliente._id) {
            await this.$store.dispatch('pedidosClientes/atualizar', this.pedido_cliente);
          } else {
            await this.$store.dispatch('pedidosClientes/salvar', this.pedido_cliente);
          }
          this.$router.push({ name: 'PedidosClientesLista'});
        } else {
          this.$store.commit('setMensagemErro', 'Adicione pelo menos um produto');
        }
      } else {
        this.$store.commit('setMensagemErro', 'Selecione um cliente');
      }
    },
    cancelar () {
      this.$router.push({ name: 'PedidosClientesLista'});
    },
    adicionarParticipante(participante){
      this.pedido_cliente.participante.cnpj_cpf = formatador.formatarCnpj(this.pedido_cliente.participante.cnpj_cpf);
      this.$store.commit('pedidosClientes/SET_PARTICIPANTE', participante);
    },
    abrirBuscaProdutos () {
      this.$refs.dialogBuscarProduto.abrir();
    },
    selecionarProduto (produto_selecionado) {
      this.produto = {
        produto: produto_selecionado._id,
        codigo: produto_selecionado.codigo,
        codigo_barra: produto_selecionado.codigo_barra,
        descricao: produto_selecionado.descricao,
        quantidade: 0,
        unidade: produto_selecionado.unidade,
        desconto: 0,
        valor: produto_selecionado.valor,
        subtotal: 0,
      };
      this.dialogQuantidade = true;
      setTimeout(() =>{
        this.$refs.quantidade.focus()
      }, 100);
    },
    calcularSubtotal () {
      this.produto.subtotal = (this.produto.quantidade * this.produto.valor) - ((this.produto.quantidade * this.produto.valor) * (this.produto.desconto / 100));
    },
    salvarProduto () {
      if (this.modoEdicao) {
        this.$store.commit('pedidosClientes/UPDATE_PRODUTO', this.produto);
        this.modoEdicao = false;
      } else {
        let index = this.pedido_cliente.produtos.findIndex(produto => produto.codigo == this.produto.codigo);
        if (index > -1) {
          this.$store.commit('setMensagemErro', 'Produto já adicionado ao pedido!');
        } else {
          this.$store.commit('pedidosClientes/SET_PRODUTO', this.produto);
        }
      }

      this.$store.commit('pedidosClientes/SET_TOTAIS', this.totais);

      this.produto = {};
      this.dialogQuantidade = false;
    },
    removerProduto (item){
      this.$root.$confirmacao.open('Remover', 'Tem certeza que deseja remover este produto ?')
      .then((confirmado) => {
        if(confirmado){
          this.$store.commit('pedidosClientes/EXCLUIR_PRODUTO', item);

          this.$store.commit('pedidosClientes/SET_TOTAIS', this.totais);

          this.produto = {};
        }
      });
    },
    editarProduto (produto_selecionado) {
      this.produto = deepClone(produto_selecionado);
      this.dialogQuantidade = true;
      this.modoEdicao = true;
    }
  },
}
</script>

<style>
  
</style>